<style>
  footer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
    width: 300px;
  }

  a {
    color: var(--primary-color);
  }

  a:hover {
    color: var(--accent-color);
  }
</style>

<!-- SVG Icons are from https://simpleicons.org -->
<footer>
  <a class="link h2 w2" href="https://github.com/bryik" title="GitHub">
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill-rule="evenodd"
      clip-rule="evenodd"
      stroke-linejoin="round"
      stroke-miterlimit="1.414">
      <path
        d="M8 0C3.58 0 0 3.582 0 8c0 3.535 2.292 6.533 5.47
        7.59.4.075.547-.172.547-.385
        0-.19-.007-.693-.01-1.36-2.226.483-2.695-1.073-2.695-1.073-.364-.924-.89-1.17-.89-1.17-.725-.496.056-.486.056-.486.803.056
        1.225.824 1.225.824.714 1.223 1.873.87
        2.33.665.072-.517.278-.87.507-1.07-1.777-.2-3.644-.888-3.644-3.953
        0-.873.31-1.587.823-2.147-.083-.202-.358-1.015.077-2.117 0 0 .672-.215
        2.2.82.638-.178 1.323-.266 2.003-.27.68.004 1.364.092 2.003.27
        1.527-1.035 2.198-.82 2.198-.82.437 1.102.163 1.915.08 2.117.513.56.823
        1.274.823 2.147 0 3.073-1.87 3.75-3.653 3.947.287.246.543.735.543 1.48 0
        1.07-.01 1.933-.01 2.195 0 .215.144.463.55.385C13.71 14.53 16 11.534 16
        8c0-4.418-3.582-8-8-8" />
    </svg>
  </a>

  <a
    class="link h2 w2"
    href="https://stackoverflow.com/users/6591491/bryik"
    title="Stack Overflow">
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill-rule="evenodd"
      clip-rule="evenodd"
      stroke-linejoin="round"
      stroke-miterlimit="1.414">
      <path
        d="M12.658
        14.577v-4.27h1.423V16H1.23v-5.693H2.65v4.27h10.006zm-8.583-1.423h7.16V11.73h-7.16v1.424zm.173-3.235l6.987
        1.465.3-1.38L4.55 8.54l-.302 1.38zm.906-3.365l6.47
        3.02.602-1.295-6.47-3.02-.602 1.295zm1.81-3.19l5.478
        4.57.906-1.078-5.477-4.57-.905 1.077zM10.502 0L9.338.863l4.27 5.735
        1.164-.862L10.5 0z" />
    </svg>
  </a>

  <a class="link h2 w2" href="https://twitter.com/bryik_ws" title="Twitter">
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill-rule="evenodd"
      clip-rule="evenodd"
      stroke-linejoin="round"
      stroke-miterlimit="1.414">
      <path
        d="M16 3.038c-.59.26-1.22.437-1.885.517.677-.407 1.198-1.05
        1.443-1.816-.634.375-1.337.648-2.085.795-.598-.638-1.45-1.036-2.396-1.036-1.812
        0-3.282 1.468-3.282 3.28 0 .258.03.51.085.75C5.152 5.39 2.733 4.084
        1.114 2.1.83 2.583.67 3.147.67 3.75c0 1.14.58 2.143 1.46
        2.732-.538-.017-1.045-.165-1.487-.41v.04c0 1.59 1.13 2.918 2.633
        3.22-.276.074-.566.114-.865.114-.21 0-.416-.02-.617-.058.418 1.304 1.63
        2.253 3.067 2.28-1.124.88-2.54 1.404-4.077 1.404-.265
        0-.526-.015-.783-.045 1.453.93 3.178 1.474 5.032 1.474 6.038 0 9.34-5
        9.34-9.338 0-.143-.004-.284-.01-.425.64-.463 1.198-1.04 1.638-1.7z"
        fill-rule="nonzero" />
    </svg>
  </a>

  <a
    class="link h2 w2"
    href="https://observablehq.com/@bryik"
    title="Observable">
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      fill-rule="evenodd"
      clip-rule="evenodd"
      stroke-linejoin="round"
      stroke-miterlimit="1.414">
      <path
        d="M16 25.4723C14.7073 25.4723 13.5873 25.1999 12.64 24.6554C11.6961
        24.113 10.9414 23.3446 10.4618 22.4375C9.95197 21.4937 9.57873 20.4956
        9.35116 19.4676C9.10972 18.3526 8.99209 17.219 9 16.0833C9 15.1841
        9.06067 14.3244 9.18316 13.5024C9.30451 12.6814 9.52967 11.8468 9.85751
        10.9998C10.1853 10.1527 10.6077 9.41827 11.1222 8.79547C11.6578 8.15743
        12.3504 7.63808 13.1463 7.2776C13.9816 6.88848 14.9313 6.69444 16
        6.69444C17.2927 6.69444 18.4127 6.96673 19.36 7.51127C20.3039 8.05367
        21.0586 8.82211 21.5382 9.72914C22.0445 10.6628 22.4144 11.6528 22.6489
        12.6991C22.8822 13.7444 23 14.8732 23 16.0833C23 16.9826 22.9393 17.8422
        22.8169 18.6643C22.6863 19.5177 22.4556 20.3566 22.1285 21.1669C21.7902
        22.0139 21.3702 22.7484 20.8638 23.3712C20.3575 23.9939 19.6878 24.5
        18.8536 24.8891C18.0195 25.2782 17.0687 25.4723 16 25.4723ZM18.4711
        18.569C19.1405 17.9189 19.5129 17.0193 19.5 16.0833C19.5 15.1162 19.1651
        14.2877 18.4967 13.5976C17.8271 12.9075 16.9951 12.5625 16
        12.5625C15.0049 12.5625 14.1731 12.9075 13.5033 13.5976C12.8476 14.255
        12.4854 15.1522 12.5 16.0833C12.5 17.0504 12.8348 17.879 13.5033
        18.569C14.1731 19.2591 15.0049 19.6042 16 19.6042C16.9951 19.6042
        17.8189 19.2591 18.4711 18.569ZM16 30.1667C23.7315 30.1667 30 23.8608 30
        16.0833C30 8.30581 23.7315 2 16 2C8.26849 2 2 8.30581 2 16.0833C2
        23.8608 8.26849 30.1667 16 30.1667Z" />
    </svg>
  </a>
</footer>
